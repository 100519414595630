import {EventEmitter, inject, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  @Output() userChanged = new EventEmitter();
  private readonly authDomain: string = environment.urls.amon;
  readonly sessionMaxLimit: number = 1800000 ;  // 1800000 (30 minutos);

  private httpClient = inject(HttpClient);


  static getSession() {
    return sessionStorage.getItem('[horus]_user') || null;
  }

  static getUser() {
    const session = AuthService.getSession();
    return (session) ? JSON.parse(session).usuario : null;
  }

  static isAuthenticated(): boolean {
    return (sessionStorage.getItem('[horus]_user') !== null);
  }

  static isCheckFullMode(): boolean {
    return (AuthService.getUser()?.partner?.checks_mode === 'full');
  }

  buildHeader(type: number | string) {
    const session = AuthService.getSession();
    const tk = (session) ? JSON.parse(session).token : null;
    const rtk = (session) ? JSON.parse(session).refreshToken : null;
    let headers: HttpHeaders;
    switch (type) {
      case 1:
        headers = new HttpHeaders().set('Accept', 'application/json').append('Content-Type', 'application/json;charset=UTF-8');
        break;
      case 2:
        headers = new HttpHeaders().set('Accept', 'application/json')
          .append('Content-Type', 'application/json;charset=UTF-8')
          .append('authorization', 'Bearer ' + tk);
        break;
      case 3:
        headers = new HttpHeaders().set('Accept', 'application/json')
          .append('Content-Type', 'application/json;charset=UTF-8')
          .append('authorization', 'Bearer ' + rtk);
        break;
    }
    return headers;
  }

  setSessionLimits() {
    const sessionLimit = Date.now() + this.sessionMaxLimit;
    sessionStorage.setItem('[horus]_sessionLimit', '' + sessionLimit);
  }

  getSessionLimit() {
    return +sessionStorage.getItem('[horus]_sessionLimit');
  }


  setToken(tk: string, rtk: string) {
    const session = AuthService.getSession();
    const customUser = (session) ? JSON.parse(session) : {token: ''};
    customUser.token = tk;
    customUser.refreshToken = rtk;
    this.setUser(customUser);
    this.setSessionLimits();
    return customUser;
  }

  signinUser(horusUser: any) {
    const url = this.authDomain + '/auth/login';
    const body = JSON.stringify(horusUser);
    const headers = this.buildHeader(1);
    return this.httpClient.post<any>(url, body, {observe: 'body', headers});
  }


  setUser(u: {token: string, refreshToken?: string, usuario?: any, payload?: any}) {
    const userStr = JSON.stringify(u);
    sessionStorage.setItem('[horus]_user', userStr);
    this.userChanged.emit(u);
    return true;
  }

  patchPartner(p: any) {
    const u = JSON.parse(AuthService.getSession());
    u.usuario.partner = p;
    sessionStorage.setItem('[horus]_user', JSON.stringify(u));
    return true;
  }

  logout() {
    for (const itemData in sessionStorage) {
      if (sessionStorage.hasOwnProperty(itemData) && itemData.match(/^\[horus]/)) {
        sessionStorage.removeItem(itemData);
      }
    }
    return true;
  }

  actLikeRole(role: string) {
    const url = this.authDomain + '/auth/horus/change-role';
    const headers = this.buildHeader(2);
    return this.httpClient.post<any>(url, {horusRole: role}, {observe: 'body', headers});
  }

  refreshSession() {
    const url = this.authDomain + '/auth/refresh';
    const headers = this.buildHeader(3);
    return this.httpClient.get<any>(url, {observe: 'body', headers});
  }

  gmailOauth() {
    const url = this.authDomain + '/auth/google/connect-url';
    const redirect_uri = environment.urls.domain + '/horus/gmail-oauth2';
    const headers = this.buildHeader(2);
    return this.httpClient.post<any>(url, {redirect_uri}, {observe: 'body', headers});
  }

  gmailOauthCheck(Gresult: any) {
    const url = this.authDomain + '/auth/google/client-connect-callback';
    const customGresult = {
      ...Gresult,
      redirect_uri: environment.urls.domain + '/horus/gmail-oauth2'
    }
    const body = JSON.stringify(customGresult);
    const headers = this.buildHeader(1);
    return this.httpClient.post<any>(url, body, {observe: 'body', headers});
  }

}
