import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService, RolesService} from '@services';


@Injectable({
  providedIn: 'root',
})
export class TermsGuard {

  constructor(private router: Router) {
  }

  canActivate() {
    const u = AuthService.getUser();
    if (u?.terms && u?.partner?.agreement) {
      return true;
    }
    if (RolesService.isPartnerAdm && !u?.partner?.agreement) {
      this.router.navigate(['/not-signed/contrato/']).then(() => {
        console.log('Unsigned');
      });
    } else if (!u?.terms) {
      this.router.navigate(['/not-signed/privacidad/']).then(() => {
        console.log('Unsigned');
      });
    }
  }
}
